import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../components/layout/Layout';

const NotFoundPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/404-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`Erreur 404 | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, aménagement, isolation intérieure et extérieure à Roanne`}
      pageDescription=""
      pageKeywords=""
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">404</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">404</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section>
        <div className="container pt-90">
          <div className="section-title">
            <div className="row justify-content-md-center">
              <div className="col-md-10">
                <div className="text-center mb-60">
                  <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                    <div className="title-wrapper">
                      <h2 className="title"><span className="text-theme-colored1">Oups!</span> Page non trouvée!</h2>
                      <div className="title-seperator-line"></div>
                      <div className="paragraph">
                        <p>Nous sommes désolés mais nous n'arrivons pas à trouver la page que vous avez demandée. Cela peut être dû au fait que vous avez mal saisi l'adresse Web.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-content">
            <div className="row">
              <div className="col-12 text-center">
                {process.env.NODE_ENV === "development" ? (
                  <>
                    <p>Try creating a page in <code>src/pages/</code></p>
                  </>
                ) : null}
                <Link to="/" className="btn btn-outline-theme-colored1 btn-outline btn-lg btn-round">Aller à l'accueil</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage